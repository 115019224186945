<template>
  <div class="clients">
    <div class="intro">
      <div class="container grid-md">
        <div class="columns">
          <div class="column col-6 col-md-12 col-lg-6 col-offset-1 col-md-offset-0">
            <h1 v-html="$t('navigation.imprint')"></h1>
          </div>
        </div>
      </div>

    </div>
    <div class="content content--no-tabs">
      <div class="container grid-md">
        <div class="columns">
          <div class="column col-9 col-sm-12 col-md-11 col-offset-1 col-md-offset-0">
            <div v-html="$t('imprint.intro')"></div>

            <h2 class="h4 margin--m" v-html="$t('imprint.part_1_headline')"></h2>
            <div v-html="$t('imprint.part_1_text')"></div>

            <h2 class="h4 margin--m" v-html="$t('imprint.part_2_headline')"></h2>
            <div v-html="$t('imprint.part_2_text')"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Imprint',
  components: {},
}
</script>

<style lang="scss" scoped>
  .intro {
    padding: 2rem 0 4rem 0;
  }

  .content {
    background: $color-primary-50;
  }
</style>
